import { DIMENSIONS } from "@assets/styles/styleConstants";

const footerContainer = {
  backgroundColor: "#1a1e22",
  width: "100%",
  alignItems: "center",
  justifyItems: "center",
  justifyContent: "center",
  padding: ["0.5rem 0"],
};

const footerWrapper = {
  maxWidth: "1200px",
  width: "100%",
  mx: "auto",
  flexGrow: 1,
  color: "white",
  alignItems: "center",
  justifyContent: "center",
};

const logo = {
  opacity: "0.5",
  height: DIMENSIONS.logoHeight,
};

const footerText = {
  lineHeight: "body",
  color: "white",
  fontWeight: 2,
  padding: ["1rem", null, null, null, null, 0],
};

export const footerSX = {
  footerContainer,
  footerWrapper,
  logo,
  footerText,
};
