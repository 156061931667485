import { useState } from "react";
import QuestionMarkCircleOutlineIcon from "@pingux/mdi-react/QuestionMarkCircleOutlineIcon";
import { Box, IconButton, PopoverMenu, Item, Menu, Icon } from "@pingux/astro";
import { headerSX } from "./HeaderSX";

interface DropdownProps {
  items: { label: string; url: string }[];
}

const HelpButton: React.FC<DropdownProps> = ({ items }) => {
  const [isShowMenu, setShowMenu] = useState(false);

  return (
    <Box>
      <PopoverMenu
        isOpen={isShowMenu}
        onOpenChange={() => setShowMenu((prevIsShowMenu) => !prevIsShowMenu)}
      >
        <IconButton
          aria-label="Help"
          sx={{
            p: 0,
            border: "none",
            "&.is-hovered": {
              boxShadow: "none",
              backgroundColor: "transparent",
            },
          }}
        >
          <Icon
            icon={QuestionMarkCircleOutlineIcon}
            size="sm"
            title={{
              name: "Help Icon",
            }}
            variant=""
          />
        </IconButton>
        <Menu>
          {items.map((item) => (
            <Item
              as="a"
              variant="a"
              key={item?.url}
              target="_blank"
              href={item?.url}
              sx={headerSX.supportLinks}
              aria-label={item?.label}
            >
              {item?.label}
            </Item>
          ))}
        </Menu>
      </PopoverMenu>
    </Box>
  );
};

export default HelpButton;
