import { SPACING, DIMENSIONS, Z_INDEX } from "@assets/styles/styleConstants";

const headerContainer = {
  position: "fixed",
  zIndex: Z_INDEX.header,
  top: 0,
  width: "100%",
} as const;

const header = {
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
};

const logoContainer = {
  py: SPACING.small,
  alignItems: "center",
};

const logo = {
  height: DIMENSIONS.logoHeight,
  verticalAlign: "middle",
  mr: SPACING.medium,
};

const navContainer = {
  alignItems: "center",
  justifyContent: "flex-end",
};

const isVisibleOnMobile = {
  display: ["none", "none", "block", "block", "block", "block"],
};

const isVisibleOnDesktop = {
  display: ["none", "none", "none", "none", "block", "block"],
};

const dropdownMenu = {
  maxWidth: "unset",
  pl: "200px",
  p: SPACING.small,
  "& > li": {
    p: "0",
  },
};

const dropdownMenuItem = {
  textDecoration: "none",
  padding: ".675rem 1rem",
  pr: "2.25rem",
  "&.is-hovered": { textDecoration: "none" },
  "&.is-pressed": { textDecoration: "none" },
  "&.is-focused": { textDecoration: "none" },
  "&.is-visited": { textDecoration: "none" },
};

const supportLinks = {
  boxSizing: "border-box",
  margin: "0px",
  minWidth: "0px",
  backgroundColor: "transparent",
  textDecoration: "none",
  padding: "10px",
  outline: "none",
  cursor: "pointer",
  color: "gray-600",
  display: "flex",
  flexDirection: "column",
  "&:hover": {
    backgroundColor: "gray-100",
    color: "gray-900",
  },
} as const;

export const headerSX = {
  headerContainer,
  header,
  logoContainer,
  logo,
  navContainer,
  isVisibleOnMobile,
  isVisibleOnDesktop,
  dropdownMenu,
  dropdownMenuItem,
  supportLinks,
};
