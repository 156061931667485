import { DIMENSIONS } from "@assets/styles/styleConstants";

const mainContainer = {
  height: "100%",
  width: "100%",
  bg: "white",
  alignItems: "center",
  justifyItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
};

const childrenWrapper = {
  paddingTop: DIMENSIONS?.headerHeight,
  maxWidth: "1540px",
  width: "100%",
  px: ["1.5rem", null, null, null, null, null],
  pb: ["9.375rem", null, null, null, null, null],
  flexGrow: "1",
};

export const LayoutSX = {
  mainContainer,
  childrenWrapper,
};
