import { useEffect } from "react";
import useFetchConfig from "hooks/useFetchConfigHook";
import ErrorBoundary from "./components/ErrorBoundary";
import Routes from "./routes";
import { AuthProvider } from "state/context/AuthContext";
import { initGTM } from "utils/GTMEvents";
import { ENV_CONFIG_KEY } from "utils/constants";
import { HelmetProvider } from "react-helmet-async";

const App = () => {
  const { envConfig, error, loading } = useFetchConfig(ENV_CONFIG_KEY);

  useEffect(() => {
    if (!loading && !error) {
      const origin = window?.location?.origin || "";
      const currentEnvConfig = envConfig?.find(
        (configObj) => configObj.url === origin
      );

      if (currentEnvConfig?.gtmId) {
        initGTM({
          gtmId: currentEnvConfig.gtmId,
          dataLayer: [
            {
              "gtm.start": new Date().getTime(),
              "gtm.event": "",
              category: "App",
              platform: "web",
            },
          ],
        });
      }
    }
  }, [envConfig, error, loading]);

  return (
    <HelmetProvider>
      <AuthProvider>
        <ErrorBoundary>
          <Routes />
        </ErrorBoundary>
      </AuthProvider>
    </HelmetProvider>
  );
};

export default App;
