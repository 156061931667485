import { Box, Grid, Link, Text } from "@pingux/astro";
import {
  copyrightText,
  navigationLinks,
  socialMediaLinks,
} from "./FooterConfig";
import { footerSX } from "./FooterSX";

const Footer = () => {
  return (
    <Box as="footer" sx={footerSX.footerContainer}>
      <Grid
        sx={footerSX.footerWrapper}
        className="footerWrapper"
        columns={["1fr", null, null, null, null, "repeat(3, max-content)"]}
        gap={"1rem"}
      >
        {copyrightText && (
          <Box textAlign="center">
            <Text sx={footerSX.footerText}>{copyrightText}</Text>
          </Box>
        )}

        <Box
          className="logo"
          alignItems="center"
          justifyContent="center"
          sx={{ margin: ["0", "0", "0", "0", "0", "1.6rem"] }}
        ></Box>

        <Box
          className="navigation-wrapper"
          sx={{
            flexDirection: ["column", null, null, null, null, "row"],
            padding: ["0 1rem 1rem 1rem", null, null, null, null, 0],
          }}
        >
          <Box
            className="navigtion-link"
            mr={[0, null, null, null, null, "1rem"]}
            gap={["0.5rem", "1rem"]}
            justifyContent={["center", null, null, null, null, null]}
            alignItems={["center"]}
            mb={["2rem", null, null, null, null, 0, 0]}
            isRow
            as="ul"
          >
            {navigationLinks.map((navObj) => (
              <Box as="li" key={navObj.ariaLabel}>
                <Link
                  href={navObj.href}
                  aria-label={navObj.ariaLabel}
                  sx={{
                    cursor: "pointer",
                    color: "white",
                    fontWeight: 2,
                    textDecoration: "underline",
                    listStyleType: "none",
                  }}
                >
                  {navObj.children}
                </Link>
              </Box>
            ))}
          </Box>

          <Box
            className="social-icons"
            justifyContent="center"
            gap="1rem"
            isRow
            as="ul"
          >
            {socialMediaLinks.map((socialMediaLinksObj) => (
              <Box as="li" key={socialMediaLinksObj["ariaLabel"]}>
                <Link
                  target="_blank"
                  href={socialMediaLinksObj["href"]}
                  sx={{
                    color: "white",
                    listStyleType: "none",
                  }}
                  aria-label={socialMediaLinksObj["ariaLabel"]}
                >
                  {socialMediaLinksObj.icon}
                </Link>
              </Box>
            ))}
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};

export default Footer;
