import {
  FacebookPage,
  Github,
  Instagram,
  Linkedin,
  Mail,
  Twitter,
  Youtube,
} from "./SocialMediaSvg/SocialMediaSvg";

type SocialLink = {
  href: string;
  label: string;
  icon: JSX.Element;
  ariaLabel: string;
};

export const footerLogo = "/ping-logo-horizontal-black.svg";

export const copyrightText =
  "© Copyright 2025 Ping Identity. All rights reserved.";

export const navigationLinks = [
  {
    href: "https://www.pingidentity.com/en/legal.html",
    children: "Legal",
    ariaLabel: "Legal",
  },
  {
    href: "https://www.pingidentity.com/en-us/docs/legal/privacy",
    children: "Privacy",
    ariaLabel: "Privacy Policy",
  },
  {
    href: "https://www.pingidentity.com/en/company/security-at-ping-identity.html",
    children: "Security",
    ariaLabel: "Security",
  },
  {
    href: "https://www.pingidentity.com/en/legal/pingmfa-campaign-terms-of-service.html",
    children: "SMS Campaigns",
    ariaLabel: "SMS Campaigns",
  },
];

export const socialMediaLinks: SocialLink[] = [
  {
    href: "https://x.com/pingidentity",
    icon: <Twitter />,
    ariaLabel: "Ping Identity Twitter Page",
    label: "Twitter Icon",
  },
  {
    href: "https://linkedin.com/company/pingidentity",
    icon: <Linkedin />,
    ariaLabel: "Ping Identity Linkedin Page",
    label: "Linkedin Icon",
  },
  {
    href: "https://facebook.com/pingidentity",
    icon: <FacebookPage />,
    ariaLabel: "Ping Identity Facebook Page",
    label: "Facebook Icon",
  },
  {
    href: "https://www.youtube.com/user/pingidentitytv",
    icon: <Youtube />,
    ariaLabel: "Ping Identity Youtube Page",
    label: "Youtube Icon",
  },
  {
    href: "https://github.com/pingidentity",
    icon: <Github />,
    ariaLabel: "Ping Identity Github Page",
    label: "Github Icon",
  },
  {
    href: "mailto:contact@pingidentity.com",
    icon: <Mail />,
    ariaLabel: "Ping Identity Contact Email",
    label: "Mail Icon",
  },
  {
    href: "https://www.instagram.com/pingidentity",
    icon: <Instagram />,
    ariaLabel: "Ping Identity Instagram Page",
    label: "Instagram Icon",
  },
];
