import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import { TagsContextProvider } from "@state/context/TagsContext";

const HomePage = lazy(() => import("@pages/HomePage"));
const LoginPage = lazy(() => import("@pages/Login"));
const AboutPage = lazy(() => import("@pages/About"));
const ItemDetailsPage = lazy(() => import("@pages/ItemDetailsPage"));
const AuthorDetailPage = lazy(() => import("@pages/AuthorDetailPage"));
const ItemBrowsePage = lazy(() => import("@pages/ItemBrowsePage"));
const NotFoundPage = lazy(() => import("@pages/NotFound"));

const SideNavLayout = lazy(() => import("@components/SideNav"));

const PublicRoutes: RouteObject[] = [
  {
    path: "/",
    element: <PublicRoute restricted={true} />, // restriced flag in public routes indicate that only non-authenticated user can access this route
    children: [
      { index: true, element: <LoginPage /> },
      { path: "login", element: <LoginPage /> },
    ],
  },
  {
    path: "policy",
    element: <h1>Policy Page</h1>,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
];

const PrivateRoutes: RouteObject[] = [
  {
    path: "/",
    element: <PrivateRoute />,
    children: [
      {
        element: (
          <TagsContextProvider>
            <SideNavLayout />
          </TagsContextProvider>
        ),
        children: [
          {
            index: true,
            path: "home",
            element: <HomePage />,
          },
          {
            path: "browse",
            element: <ItemBrowsePage />,
          },
        ],
      },
      {
        path: "about",
        element: <AboutPage />,
      },
      {
        path: "item/:id",
        element: <ItemDetailsPage />,
      },
      { path: "author/:id", element: <AuthorDetailPage /> },
      { path: "*", element: <NotFoundPage /> },
    ],
  },
];

export { PublicRoutes, PrivateRoutes };
