import { useEffect, useState } from "react";
import { Tags, SchemaConfig } from "types/tagHooksTypes";
import { fetchSchema, fetchTagsData } from "services/tagsService";
import { ApiErrorInterface } from "types/types";
import { createApiError, sortCollectionSequence } from "utils/commonUtils";

const useFetchTags = () => {
  const [tags, setTags] = useState<Tags | null>(null);
  const [tagsError, setError] = useState<ApiErrorInterface | null>(null);
  const [tagsLoading, setLoading] = useState<boolean>(true);
  const [tagsSchemaConfig, setTagsSchemaConfig] = useState<
    SchemaConfig[] | null
  >(null);
  const CONFIG_KEY = "navData";

  useEffect(() => {
    async function getTags() {
      try {
        const schemaData: SchemaConfig[] = await fetchSchema(CONFIG_KEY);
        const tagsData: Tags = await fetchTagsData();

        setTagsSchemaConfig(schemaData);
        const updatedTagsData = { ...tagsData };
        updatedTagsData["collection"] = sortCollectionSequence(
          tagsData?.collection || []
        );
        setTags(updatedTagsData);
      } catch (error: any) {
        setError(createApiError(error?.data, error?.status));
      } finally {
        setLoading(false);
      }
    }
    getTags();
  }, []);
  return { tags, tagsError, tagsLoading, tagsSchemaConfig };
};

export default useFetchTags;
