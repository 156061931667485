import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { PrivateRoutes, PublicRoutes } from "./RoutePath";
import Layout from "@components/Layout";

const routes = [
  {
    path: "/",
    element: <Layout />,
    children: [...PublicRoutes, ...PrivateRoutes],
  },
];

const router = createBrowserRouter(routes);

const RoutesComponent = () => {
  return <RouterProvider router={router} />;
};

export default RoutesComponent;
