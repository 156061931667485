import { Box, Loader } from "@pingux/astro";
import { loaderStyle } from "./LoaderSX";

interface PageLoaderProps {
  loaderOverlayStyle?: Record<string, any>;
}

const PageLoader = (props: PageLoaderProps) => {
  const { loaderOverlayStyle = {} } = props;
  return (
    <Box
      className="overlay"
      isRow
      alignItems="center"
      justifyContent="center"
      sx={{ ...loaderStyle, ...loaderOverlayStyle }}
    >
      <Loader size={"sm"} color={"gray-800"} />
    </Box>
  );
};

export default PageLoader;
