import { Suspense } from "react";
import { Box } from "@pingux/astro";
import { Outlet } from "react-router-dom";

import { LayoutSX } from "./LayoutSX";
import Header from "@components/Header";
import Footer from "@components/Footer";
import ScrollToTop from "@components/ScrollTop";
import PageLoader from "@components/Loader/Loader";

const Layout: React.FC = () => {
  return (
    <>
      <Header />
      <ScrollToTop />
      <Box as="main" sx={LayoutSX.mainContainer}>
        <ScrollToTop />
        <Suspense fallback={<PageLoader />}>
          <Box sx={LayoutSX?.childrenWrapper}>
            <Outlet />
          </Box>
        </Suspense>
      </Box>
      <Footer />
    </>
  );
};

export default Layout;
