import { ApiErrorInterface, ApiErrorData } from "types/types";
import {
  ERROR_DEFAULTS,
  DEBOUNCE_DEFAULT_DELAY,
  REGEX_CONTENT_BETWEEN_PARENTHESES,
  REGEX_REMOVE_PARENTHESES_AND_CONTENT,
  TAGS_CHAR_LIMIT,
  DEFAULT_TRUNCATE_SUFFIX,
} from "./constants";
import { TagEntity } from "types/tagHooksTypes";
import {
  ItemApiResponseInterface,
  Item as ItemInterface,
} from "types/itemsHooksTypes";
import { DUMMY_ITEM_LIST, STATUS_CODES } from "utils/constants";

export function isEmpty(
  value: null | string | object | Record<string, any> | undefined
): boolean {
  // Check if the value is null or undefined
  if (value == null) return true;

  // Check if the value is an array or a string and if its length is 0
  if (typeof value === "string" || Array.isArray(value)) {
    return value.length === 0;
  }

  // Check if the value is an object and if it has no own properties
  if (typeof value === "object") {
    return Object.keys(value).length === 0;
  }

  return false;
}

export function collectionFilterQueryMapping(selectedFilterId: string): string {
  return (
    {
      essential: "isEssential",
      new: "isNew",
      featured: "isFeatured",
    }[selectedFilterId] || ""
  );
}

export function debounce<T extends (...args: any[]) => void>(
  callbackFunction: T,
  delay = DEBOUNCE_DEFAULT_DELAY
): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | null;

  return function (...args: Parameters<T>) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      callbackFunction(...args);
    }, delay);
  };
}

export function createApiError(
  errorData: ApiErrorData,
  status: number | string
): ApiErrorInterface {
  const {
    id = ERROR_DEFAULTS?.id,
    error = ERROR_DEFAULTS?.error,
    message = ERROR_DEFAULTS?.message,
  } = errorData || {};
  const statusCode = status || STATUS_CODES.INTERNAL_SERVER_ERROR;

  return {
    id,
    error,
    message,
    status: statusCode,
  };
}

export function formatJsonString(input: string): object | {} {
  try {
    const jsonObject = JSON.parse(input);
    const formattedString = JSON.stringify(jsonObject, null, 2);
    return JSON.parse(formattedString);
  } catch (error) {
    console.error("Error formatting JSON:", error);
    return {};
  }
}

export function convertToUrlEncoded(data: object): string {
  const jsonString = JSON.stringify(data);
  return encodeURIComponent(jsonString);
}

export function convertToBase64(rawString: string): string {
  const base64String = btoa(rawString);
  return base64String;
}

export function extractAndRemoveParenthesesContent(str: string) {
  const match = str.match(REGEX_CONTENT_BETWEEN_PARENTHESES);
  const splitProps = match?.[1].split(",") as string[];
  const contentBetweenParentheses: string[] = splitProps?.map((configValues) =>
    configValues.trim().toLowerCase()
  );
  const contentWithoutParentheses = str
    .replace(REGEX_REMOVE_PARENTHESES_AND_CONTENT, "")
    .trim();
  return { contentBetweenParentheses, contentWithoutParentheses };
}

export function sortCollectionSequence(collectionList: TagEntity[]) {
  // NOTE: If the name of Collection Changes then we have to make changes here as well
  const newCollectionList = [...collectionList];
  if (!newCollectionList?.length) {
    return [];
  }

  const sortOrder = {
    Featured: 1,
    New: 2,
    Essentials: 3,
  };

  const sortedCollectionList: TagEntity[] = newCollectionList.sort(
    (a, b) => sortOrder[a.name] - sortOrder[b.name]
  );

  return sortedCollectionList;
}

export function truncateText(
  text: string,
  noOfChars = TAGS_CHAR_LIMIT,
  suffixString = DEFAULT_TRUNCATE_SUFFIX
) {
  if (text.trim().length > TAGS_CHAR_LIMIT)
    return text.slice(0, noOfChars) + suffixString;
  return text;
}

export const getEmbeddedItems = (
  pageNo: number,
  items: unknown,
  loading: boolean | undefined
): ItemInterface[] => {
  // If it's the first page and loading, return the dummy data
  if (pageNo === 1 && loading) {
    return DUMMY_ITEM_LIST as ItemInterface[];
  }

  // Otherwise, return the actual items or an empty array
  return (items?.["_embedded"]?.items as ItemInterface[]) || [];
};

export const checkValidDate = (date: string) => {
  return !isNaN(new Date(date).getTime());
};

export const formatDate = (date: string) => {
  return new Date(date)?.toLocaleDateString();
};

export const cardLayoutSelector = (layoutType: string) => {
  return (
    {
      A: "1fr",
      B: "1fr 5fr",
    }[layoutType] || "1fr"
  );
};

export const createMetaTag = (name: string, content: string | string[]) => {
  if (!content || (Array.isArray(content) && content.length === 0)) return null;

  return {
    name,
    content: Array.isArray(content) ? content.join("; ") : content,
  };
};

/**
 * Detects if the current browser is Safari.
 * @returns {boolean} - Returns true if the browser is Safari, otherwise false.
 */
export const detectSafariBrowser = (): boolean => {
  const userAgent = navigator.userAgent;
  const isSafariBrowser =
    /^((?!chrome|android).)*safari/i.test(userAgent) &&
    !userAgent.includes("CriOS"); // Exclude Chrome on iOS
  return isSafariBrowser;
};
