// Types for GTM
interface TagManagerArgs {
  gtmId: string;
  dataLayer?: object[];
}

declare global {
  interface Window {
    dataLayer: any[];
  }
}

// Initialize GTM
export const initGTM = ({ gtmId, dataLayer = [] }: TagManagerArgs): void => {
  if (!gtmId) {
    // eslint-disable-next-line no-console
    console.warn("GTM ID is required | env is development");
    return;
  }

  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      ...dataLayer,
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });

    const gtmScript = document.createElement("script");
    gtmScript.async = true;
    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
    document.head.insertBefore(gtmScript, document.head.childNodes[0]);

    // Add noscript iframe
    const noscript = document.createElement("noscript");
    const iframe = document.createElement("iframe");
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
    iframe.height = "0";
    iframe.width = "0";
    iframe.style.display = "none";
    iframe.style.visibility = "hidden";

    noscript.appendChild(iframe);
    document.body.insertBefore(noscript, document.body.childNodes[0]);

    console.log("GTM script added");
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Events occured in initializing events ", e);
  }
};

// Push events to dataLayer
export const pushToDataLayer = (data: object): void => {
  if (window.dataLayer) {
    window.dataLayer.push(data);
  }
};
