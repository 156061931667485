import { NextGenTheme } from "@pingux/astro";

export const SPACING = {
  small: "sm",
  medium: "md",
  large: "lg",
  px64: "64px",
  margin64: "64px",
};

export const DIMENSIONS = {
  logoHeight: "24px",
  headerHeight: "4.5rem",
  borderRadius: "1rem",
  iconSize: "48px",
  avatarSize: "104px",
};

export const Z_INDEX = {
  header: 1000,
};

export const TRANSITION = {
  collapse: "max-height 0.5s ease-out",
};

export const DEFAULT_BUTTON_STYLES = {
  maxWidth: "fit-content",
  display: "inline-flex",
};

export const PRIMARY_BUTTON_STYLES = {
  ...NextGenTheme.buttons.primary,
  maxWidth: "fit-content",
  display: "inline-flex",
};
