const loaderStyle = {
  position: "fixed",
  width: "100%",
  height: "100%",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  backgroundColor: "gray-400",
  zIndex: "1",
  opacity: "0.4",
} as const;

export { loaderStyle };
