import { useEffect, useState } from "react";
import { fetchSchema } from "services/tagsService";
import { ApiErrorInterface } from "types/types";
import { createApiError } from "utils/commonUtils";

const useFetchConfig = (configKey: string) => {
  const [envConfig, setEnvConfig] = useState<any[] | null>(null);
  const [error, setError] = useState<ApiErrorInterface | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!configKey) return;

    async function getConfig() {
      try {
        const schemaData = await fetchSchema(configKey);

        setEnvConfig(schemaData);
      } catch (error: any) {
        setError(createApiError(error?.data, error?.status));
      } finally {
        setLoading(false);
      }
    }
    getConfig();
  }, [configKey]);

  return { envConfig, error, loading };
};

export default useFetchConfig;
